import React, { useEffect, useState, FormEvent } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import Fetcher from "../../utils/Fetcher";

import DashboardLayer from "../DashboardLayer/DashbordLayer";
import NewEntryForm from "../NewEntryForm/NewEntryForm";

import { Box, CircularProgress, Snackbar } from "@mui/material";

import { AttributeType, CardInterface, AttributesEnum } from "../../types/types";

const fetcher = new Fetcher();

const EditCard = () => {
  const [attributes, setAttributes] = useState<AttributeType | null>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Record<string, any>>({
    name: "",
    description: "",
    image: null,
    audio: null,
  });

  const { id = "" } = useParams();
  const navigate = useNavigate();

  const [notificationMessage, setNotificationMessage] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allAttributesPromise = fetcher.fetchData<AttributeType>("/admin/attribute");
        const cardPromise = fetcher.fetchData<CardInterface>(
          `/admin/card/${id}`
        );

        const [allAttributes, card] = await Promise.all([allAttributesPromise, cardPromise]);
        
        const { attributes, ...cardValues } = card;

        setAttributes(allAttributes);
        setFormValues({
          ...cardValues,
          ...mapCurrentAttributeValues(attributes)
        })
      } catch (e) {
        navigate("/cards")
      }
      
    };

    fetchData();
  }, []);

  const mapCurrentAttributeValues = (currentAttributes: CardInterface["attributes"]) => {
    const result: Record<string, string | number> = {};
    Object.keys(currentAttributes).forEach(
      (attributeName) =>
        (result[attributeName] = currentAttributes[attributeName as keyof typeof currentAttributes].id)
    );

    return result;
  }

  const submitEditedCard = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { name, description, attributes, image, audio } =
      mapFormValuesToBody(formValues);
    const cardData = new FormData();

    cardData.append("name", name);
    cardData.append("description", description);
    cardData.append("attributes", JSON.stringify(attributes));
    cardData.append("image", image);
    cardData.append("audio", audio);
    setIsAdding(true);

    try {
      await fetcher.patchData(`/admin/card/${id}`, cardData);
      navigate(`/card/${id}`)
    } catch (e) {
      setNotificationMessage("Failed to update card");
      console.log(e);
    }

    setIsAdding(false);
  }

  const mapFormValuesToBody = (formValues: Record<string, any>) => {
    const { name, description, image, audio } = formValues;
    const attributes: Record<string, number> = {};
    Object.values(AttributesEnum).map(
      (attributeName) =>
        (attributes[`${attributeName}Id`] = formValues[attributeName])
    );

    return {
      name,
      description,
      attributes,
      image,
      audio,
    };
  };

  if (!attributes || isAdding) {
    return (
      <DashboardLayer withTopBar={false}>
        <Box display="flex" justifyContent="center" marginTop="5rem">
          <CircularProgress size={150} />
        </Box>
      </DashboardLayer>
    );
  }

  return (
    <DashboardLayer>
      <NewEntryForm
        submitFunction={submitEditedCard}
        formValues={formValues}
        setFormValues={setFormValues}
        fields={[
          {
            inputType: "stringInput",
            name: "Name",
            key: "name",
          },
          {
            inputType: "stringInput",
            name: "Description",
            key: "description",
          },
          ...Object.keys(attributes).map((attributeName) => ({
            key: attributeName,
            inputType: "select",
            name: attributeName,
            values: attributes[attributeName],
          })),
          {
            inputType: "fileInput",
            name: "Image",
            key: "image",
          },
          {
            inputType: "fileInput",
            name: "Audio",
            key: "audio",
          },
        ]}
      />
      <Snackbar
        open={notificationMessage.length !== 0}
        onClose={() => setNotificationMessage("")}
        message={notificationMessage}
        autoHideDuration={6000}
      />
    </DashboardLayer>
  );
};

export default EditCard;
