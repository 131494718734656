import React from "react";
import { Navigate } from "react-router-dom";

const RouteGuard = ({
  children
}: { children: React.ReactNode }) => {
  const hasJWT = () => localStorage.getItem("token");
  if (!hasJWT()) {
    return <Navigate to="/" />
  }
  
  return <>{children}</>;
};

export default RouteGuard;
