import React, { useState, useRef, useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { PlayCircleOutline, PauseCircleOutline } from "@mui/icons-material";

interface Props {
  soundPath: string;
}

const CardDetailsSoundField = ({ soundPath }: Props) => {
  const [isPlayingSound, setIsPlayingSound] = useState<boolean>(false);
  const cardSound = useRef(new Audio(soundPath)).current;

  const toggleSound = () => {
    if (isPlayingSound) {
      cardSound.pause();
    } else {
      cardSound.currentTime = 0;
      cardSound.play();
    }

    setIsPlayingSound(!isPlayingSound);
  };

  useEffect(() => {
    return () => {
      cardSound.currentTime = 0;
      cardSound.pause();
    }
  }, [cardSound])

  return (
    <Box display="flex" alignItems="center">
      <Typography
        sx={{
          marginRight: ".5rem",
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}
      >
        sound:
      </Typography>
      {isPlayingSound ? (
        <PauseCircleOutline sx={{ cursor: "pointer" }} onClick={toggleSound} />
      ) : (
        <PlayCircleOutline sx={{ cursor: "pointer" }} onClick={toggleSound} />
      )}
    </Box>
  );
};

export default CardDetailsSoundField;
