import React from "react";

import { Box, Typography } from "@mui/material";

interface Props {
  name: string;
  value: string;
}

const CardDetailsField = ({ name, value }: Props) => {
  return (
    <Box display="flex">
      <Typography
        sx={{
          marginRight: ".5rem",
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}
      >
        {name}:
      </Typography>
      <Typography sx={{ fontSize: "1.2rem" }}>{value}</Typography>
    </Box>
  );
};

export default CardDetailsField;
