import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage";
import CardsList from "./components/CardsList/CardsList";
import RouteGuard from "./components/RouteGuard/RouteGuard";
import AddNewCardForm from "./components/AddNewCardForm/AddNewCardForm";
import Attributes from "./components/Attributes/Attributes";
import Attribute from "./components/Attribute/Attribute";
import CardDetails from "./components/CardDetails/CardDetails";
import EditCard from "./components/EditCard/EditCard";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/cards"
          element={
            <RouteGuard>
              <CardsList />
            </RouteGuard>
          }
        />
        <Route
          path="/attributes"
          element={
            <RouteGuard>
              <Attributes />
            </RouteGuard>
          }
        />
        <Route
          path="/attribute/:type"
          element={
            <RouteGuard>
              <Attribute />
            </RouteGuard>
          }
        />
        <Route
          path="/card/:id"
          element={
            <RouteGuard>
              <CardDetails />
            </RouteGuard>
          }
        />
        <Route
          path="/card/edit/:id"
          element={
            <RouteGuard>
              <EditCard />
            </RouteGuard>
          }
        />
        <Route
          path="/add/card"
          element={
            <RouteGuard>
              <AddNewCardForm />
            </RouteGuard>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
