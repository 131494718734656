import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Fetcher from "../../utils/Fetcher";

import DashboardLayer from "../DashboardLayer/DashbordLayer";

import { Box, Button, CircularProgress, Snackbar } from "@mui/material";

import CardDetailsField from "./CardDetailsFIeld";
import CardDetailsSoundField from "./CardDetailsSoundField";

import BasicModal from "../BasicModal/BasicModal";

import { CardInterface } from "../../types/types";
import { Link } from "react-router-dom";

const fetcher = new Fetcher();

const CardDetails = () => {
  const [card, setCard] = useState<CardInterface | null>(null);
  const [isRemoveModalOpened, setRemoveModalOpened] = useState<boolean>(false);

  const [notificationMessage, setNotificationMessage] = useState<string>("");

  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const { id = "" } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCard = async () => {
      try {
        const card = await fetcher.fetchData<CardInterface>(
          `/admin/card/${id}`
        );
        setCard(card);
      } catch (e) {
        navigate("/cards");
      }
    };

    fetchCard();
  }, [id, navigate]);

  const removeCard = async () => {
    setIsRemoving(true);
    try {
      await fetcher.deleteData(`/admin/card/${id}`);
      navigate("/cards")
    } catch (e) {
      setNotificationMessage("Failed to remove this card")
      setRemoveModalOpened(false);
      console.log(e);
    }

    setIsRemoving(false);
  };

  const handleCloseRemoveModal = () => {
    setRemoveModalOpened(false);
  };

  const handleOpenRemoveModal = () => {
    setRemoveModalOpened(true);
  };

  if (!card || isRemoving) {
    return (
      <DashboardLayer withTopBar={false}>
        <Box display="flex" justifyContent="center" marginTop="5rem">
          <CircularProgress size={150} />
        </Box>
      </DashboardLayer>
    );
  }

  return (
    <DashboardLayer withTopBar={false}>
      <Box display="flex" marginTop="5rem" position="relative">
        <Box width="30%" display="flex" justifyContent="flex-end">
          <img src={card.image.url} alt="" />
        </Box>
        <Box
          width="70%"
          boxSizing="border-box"
          marginLeft="2rem"
          marginTop="1rem"
          paddingRight="5rem"
        >
          <CardDetailsField name="name" value={card.name} />
          <CardDetailsField name="description" value={card.description} />

          {Object.keys(card.attributes).map((attribute) => (
            <CardDetailsField
              name={attribute}
              value={card.attributes[attribute as keyof typeof card.attributes].value}
            />
          ))}

          <CardDetailsSoundField soundPath={card.audio.url} />

          <Box display="flex" gap=".5rem" marginTop="1rem">
            <Link to={`/card/edit/${id}`}>
              <Button variant="contained">
                Edit
              </Button>
            </Link>
            <Button
              variant="contained"
              color="error"
              onClick={handleOpenRemoveModal}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Box>
      <BasicModal
        text={"Are you sure you want to remove this card?"}
        yesText={"Yes"}
        yesHandler={removeCard}
        noText={"No"}
        noHandler={handleCloseRemoveModal}
        isOpened={isRemoveModalOpened}
      />
      <Snackbar
        open={notificationMessage.length !== 0}
        onClose={() => setNotificationMessage("")}
        message={notificationMessage}
        autoHideDuration={6000}
      />
    </DashboardLayer>
  );
};

export default CardDetails;
